/*
===============
Profile Component Navbar
===============
*/

//-top and bottom padding of navbar-
.path-children,
.links {
	padding: 0.5rem 0;
}

.person-nav {
	width: 80%;
	margin: 0 auto;
}

.categories {
	display: flex;
	flex-wrap: wrap;
	padding-top: 2rem;
	li {
		color: $black;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px 10px;
		border: 1px solid $neutral-400;
		text-transform: capitalize;
		// border-right: 0;
	}
	// styling last li
	li:last-child {
		border-radius: 0 4px 4px 0;
		border-right: 1px solid $neutral-400;
	}
}

//-internal styling of navbar-
.links {
	display: flex;
	align-items: center;
	.nav-icons {
		display: flex;
		width: 1.5rem;
		height: 1.5rem;
		margin-right: 10px;
		color: $neutral-500;
	}

	//-navbar RHS-
	.links-title {
		color: $soft-black;
		text-transform: capitalize;
		text-decoration: underline;
		text-underline-offset: 0.2rem;
	}
}

//-navbar LHS-
.navigation {
	display: flex;

	.path-children {
		display: inline-block;

		&:not(:first-child) {
			margin-left: 0.2rem;
			color: $black;
		}
		&:first-child,
		&:nth-child(3) {
			text-decoration: underline;
			text-underline-offset: 0.2rem;
			text-transform: capitalize;
			color: $black;
		}
		&:nth-child(2),
		&:nth-child(4) {
			color: $soft-black;
		}
	}
}

.nav-container {
	background-color: $neutral-400;

	.person-nav {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.person-contact ul {
			display: flex;
			.item {
				display: flex;
				align-items: center;

				&:last-child {
					margin-left: 1rem;
				}
			}
		}
	}
}

//-diving main page into left and right pane, and general styling of both-
.person-profile {
	display: flex;
	max-width: 100vw;
	min-height: 95vh;
}

//-left pane-
.left-pane {
	width: 75%;
	border-right: 1px solid $neutral-400;
	overflow: auto;
	border-bottom: 0;
	border-top: 0;

	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}
}

//-right pane-
.right-pane {
	width: 25%;
}

//-left pane-
.person-image-container,
.body {
	padding-left: 10rem;
	padding-right: 5rem;
}

//-giving top padding to body, to keep contents below the image-
.body {
	padding-top: 8rem;
	padding-bottom: 5rem;
}

.profile {
	//-top banner-
	.header {
		display: flex;
		background-color: $neutral-200;
		border-bottom: 1px solid $neutral-400;
		width: 100%;
		height: 14rem;
		padding: 0;

		//-image styling-
		.person-image-container {
			position: relative;
			top: 7.5rem;

			.person-image {
				border-radius: 10px;
				width: 258px;
				border: 1px solid $neutral-400;

				img {
					border-radius: 10px;
				}
			}
		}
	}

	//-second child of profile-
	.body {
		display: flex;
		flex-direction: column;
		width: 100%;

		.body-content {
			.name {
				color: $black;
				margin-bottom: 9px;
				overflow-wrap: normal;
				hyphens: none;
			}
			.subtitles-container {
				display: flex;
				flex-wrap: wrap;
			}
			.subtitles {
				color: $black;
				padding: 5px 2px;
				text-transform: capitalize;
			}
		}

		//-third child of body-
		.bio {
			margin-top: 1rem;
		}

		.paragraphs {
			color: $black;
			margin: 1rem 0;
			.paragraph:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
	}
}

//-Right Pane of vertical Scroll styling-

.right-panes {
	height: 100%;

	.container {
		padding: 2rem 1.5rem;

		.heading {
			color: $soft-black;
			text-transform: capitalize;
			margin-bottom: 1rem;
		}
	}
}

.list {
	width: 100%;
	.list-item {
		max-width: 100%;
		padding: 0;
	}
}
.project-list {
	.list-item {
		list-style-type: disc;
		padding: 0;
		.project {
			display: inline;
			text-decoration: underline;
			text-decoration-color: $soft-black;
			text-underline-offset: 2px;
			padding: 0;
			margin: 0px;
		}
		.project.no-decoration {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		&:not(:first-child) {
			margin-top: 1.2rem;
			padding-top: 1rem;
			border-top: 1px solid #efece3;
		}
		&::marker {
			color: $soft-black;
		}
	}
	.list-item.not-list {
		list-style-type: none;
	}
}

.publication-container {
	&:not(:first-child) {
		margin-top: 1.2rem;
		padding-top: 1rem;
		border-top: 1px solid #efece3;
	}
}

.publication-list {
	.list-item {
		&:not(:first-child) {
			margin-top: 1.2rem;
			padding-top: 1rem;
			border-top: 1px solid #efece3;
		}
	}
}

// -misc -
.underline {
	width: 100%;
	height: 1px;
}

.read-btn {
	border: 0;
	background-color: inherit;
	text-transform: capitalize;
	border-bottom: 1px solid $black;
	color: $black;
	cursor: pointer;
	margin-top: 1rem;
}

// -media-

@media screen and (max-width: 835px) {
	// -removing the person-nav as per FIGMA-
	.nav-container .person-nav {
		flex-direction: column;
		width: 100%;
		padding: 0 0.5rem;
		align-items: flex-start;
	}

	.person-profile {
		flex-direction: column;
		min-height: 0;
		min-height: 100vh;
	}

	// -left pane-
	.left-pane {
		width: 100%;
		border-bottom: 1px solid $neutral-400;
	}
	.person-image-container,
	.body {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	// -right pane-
	.right-pane {
		width: 100%;

		.container {
			padding: 1.5rem 1rem;
		}
	}
	.list {
		width: 100%;
	}
}

@media screen and (max-width: 375px) {
	// -left pane-
	.nav-container .person-nav .path-children {
		font-size: 0.6rem;
	}

	.profile {
		.body-content {
			.name {
				font-size: 1.3rem;
			}
			.subtitles-container {
				.subtitles {
					font-size: 0.7rem;
					margin-left: 2px;
				}
			}
		}

	}

	.show-btn {
		margin-top: 1rem;
	}

	.profile .body .body-content .subtitles-container .subtitles {
		padding: 0;
	}

	.person-contact .links {
		.nav-icons {
			width: 1rem;
			height: 1rem;
		}
		.links-title {
			font-size: 0.6rem;
		}
	}
}
