//-project details navbar-
.pdnav-container {
	background-color: $neutral-400;
	.pd-link {
		padding: 0.5rem 0;
	}

	.pd-nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 80%;
		margin: 0 auto;

		.pd-path {
			display: flex;
			align-items: center;
			.pathItem2,
			.pathItem3 {
				padding-left: 0.2rem;
				text-transform: capitalize;
			}
			.pathItem1 {
				text-decoration: underline;
				text-underline-offset: 4px;
				text-transform: capitalize;
			}
		}

		//-nav menu-
		.pd-main-menu {
			display: none;

			.pd-menu-item:not(:last-child) {
				padding-right: 0.5rem;
			}

			.pd-menu-item {
				.pd-active {
					color: $neutral-500;
					text-transform: capitalize;
				}
				.pd-inactive {
					text-transform: capitalize;
					color: $black;
				}
			}
		}
	}
}

.project-details {
	display: flex;
	max-width: 100vw;
	// max-height: calc(100vh - 200px);
	min-height: 95vh;
}
// dividing main page into 2 panes
//-left pane-
.pd-leftpane {
	width: 75%;
	border-right: 1px solid $neutral-400;
	border-bottom: 0;
	overflow: auto;
	&::-webkit-scrollbar {
		height: 0px;
		width: 0px;
	}
}
//-right pane of vertical scroll-
.pd-rightpane {
	// max-width: 30%;
	max-width: 25%;
	border-top: 1px solid $neutral-400;
	border-bottom: 0;
	border-top: 0;

	&::-webkit-scrollbar {
		height: 0px;
		width: 0px;
	}
}

//-left pane of vertical scroll-

.pd-left {
	// max-width: 80%;
	margin: 4rem 0;
	margin-left: 20%;
	padding-right: 20%;

	.pd-header {
		.pd-subtitle {
			padding-top: 0.5rem;
			color: $soft-black;
            font-family: 'MDSystem';
		}
	}
	.pd-about {
		padding-top: 2rem;

		.pd-about-content {
			&:not(:first-child) {
				padding-top: 0.5rem;
			}
		}
	}

	//-tags-
	.categories {
		display: flex;
		flex-wrap: wrap;
		padding-top: 2rem;
		li {
			color: $black;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			border: 1px solid $neutral-400;
			text-transform: capitalize;
		}
		li:last-child {
			border-radius: 0 4px 4px 0;
			border-right: 1px solid $neutral-400;
		}
		li:first-child {
			border-radius: 4px 0 0 4px;
		}
	}

	//-tem members-
	.pd-team {
		padding-top: 2rem;
		.contributor {
			display: grid;
			grid-template-columns: repeat(2, minmax(278px, 330px));
			gap: 1rem;
			margin-top: 1.5rem;

			.profile-link {
				border: 1px solid $neutral-400;
				display: flex;
				flex-direction: row;
				border-radius: 5px;
				padding: 0.1rem;

				//-first child of profile link-
				.profile-img {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 5px;
					width: 20%;

					min-width: 3.8rem;
					img {
						border-radius: 5px;
						width: 100%;
					}
				}

				//-default img size, incase img not present in cms-
				.default-img {
					min-width: 76px;
					min-height: 76px;
					background-color: $neutral-300;
					border-radius: 5px;
					.profile-content {
						display: none;
					}
				}

				.profile-content {
					width: 80%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					margin-left: 0.4rem;

					.profile-name {
						width: 100%;
						color: $black;
						margin-bottom: 5px;
						font-size: 0.7rem;
					}
					.title {
						width: 100%;
						color: $soft-black;
						text-transform: capitalize;
						font-size: 0.6rem;
					}
				}
			}
		}
	}

	//-publications, media, partners, funding-
	.pd-media,
	.pd-publications,
	.pd-partners,
	.pd-funding {
		padding-top: 2rem;

		.pd-heading {
			text-transform: capitalize;
		}
		.pd-content {
			padding-top: 1rem;
			display: flex;
			gap: 5px;

			//-dummy content-
			.dummy {
				width: 100px;
				height: 40px;
				background-color: $neutral-300;
			}
		}
		.container {
			margin-top: 1.5rem;
			a {
				text-decoration: underline;
			}
		}
	}
}

.pd-media,
.pd-publications {
	.paragraph {
		margin-bottom: 1rem;
	}
}

//-right pane of scrolling-
.pd-rightpane {
	width: 100%;

	.right-pane {
		width: 100%;
		height: 100%;
		.top-container {
			padding: 3rem 1.5rem;

			.status-container {
				.status-subcontainer {
					.status {
						text-transform: capitalize;
					}
					.year {
						text-transform: capitalize;
						color: $neutral-500;
					}
				}
			}
			.site-btn {
				border: 0;
				background-color: $black;
				color: $white;
				border-radius: 5px;
				padding: 10px 15px;
                font-size: 14px;
				text-transform: uppercase;
				display: inline-flex;
				align-items: center;
				margin-top: 1rem;
				font-family: 'Sculpin Variable', 'Sculpin', -apple-system,
					BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
					'Open Sans', 'Helvetica Neue', sans-serif;
				cursor: pointer;
				.site-icon {
					display: inline-flex;
					align-items: center;
					svg {
						font-size: 0.9rem;
						margin-left: 0.3rem;
					}
				}
				&:hover {
					background-color: $soft-black;
				}
			}
		}
		.bottom-container {
			padding: 3rem 1.5rem;
			.pd-nav {
				.pd-nav-item:not(:first-child) {
					padding-top: 0.8rem;
				}
				.pd-nav-item {
					text-transform: capitalize;
					color: $soft-black;
					cursor: pointer;

					&:hover {
						color: $black;
					}
				}
			}
		}
	}
}

.pd-left .pd-team .contributor .profile-link {
	transition: $main-transition;
	&:hover {
		background-color: $soft-black;

		.profile-name,
		.title {
			color: $neutral-400;
		}
	}
}

@media (max-width: 835px) {
	//-navigation-

	.pdnav-container .pd-nav {
		margin: 0;
	}

	.pdnav-container .pd-nav .pd-main-menu {
		display: flex;
		align-items: center;
		padding-left: 0.5rem;
	}

	.pd-path {
		width: 100%;
		border-bottom: 1px solid $neutral-500;
		.pathItem1,
		.pathItem2,
		.pathItem3 {
			padding-left: 0.5rem;
		}
	}

	//-undivide the page-
	.pd-main {
		flex-direction: column;
		.project-details {
			min-height: 100vh;
			flex-direction: column;

			.pd-leftpane {
				overflow: none;
				width: 100%;
				border-top: 0;
				display: block;
				min-height: 100vh;
			}
			.pd-rightpane {
				display: none;
			}
		}
	}

	//-left pane-
	.pd-left {
		max-width: 100%;
		margin: 0;
		padding: 0;

		.pd-header {
			padding: 2rem 1.8rem;
		}

		.top-container {
			padding: 0;
			flex-direction: row;

			border-top: 1px solid $neutral-400;
			border-bottom: 1px solid $neutral-400;
			margin-top: 0rem;

			.status-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 2rem 1.8rem;
				width: 100%;
				.status-subcontainer {
					.status {
						text-transform: capitalize;
					}
					.year {
						text-transform: capitalize;
						color: $neutral-500;
					}
				}

				.btn-container {
					border-radius: 5px;
					padding: 8px 10px;
					background-color: $black;
					color: $white;

					.site-btn {
						cursor: pointer;
						text-transform: uppercase;
						display: flex;
						align-items: center;
                        font-size: 14px;

						.site-icon {
							svg {
								font-size: 0.9rem;
								margin-left: 0.3rem;
							}
						}
					}
				}
			}
		}
		.pd-about {
			padding: 2rem 1.8rem;
			font-size: 16px;
			line-height: 24px;
		}

		.categories {
			padding: 0rem 1.8rem;
		}

		.pd-team {
			padding: 2rem 1.8rem;
			.contributor {
				grid-template-columns: 1fr;
			}
		}

		.pd-publications,
		.pd-media,
		.pd-partners,
		.pd-funding {
			padding-left: 1.8rem;
			padding-right: 1.8rem;
		}
		.lastItem {
			padding-bottom: 2rem;
		}
	}
}
.removeMe {
	display: none;
}
@media (max-width: 375px) {
	//-navbar-

	.pd-nav {
		min-width: 100%;
	}
	.pd-link {
		font-size: 0.6rem;
	}
	.pd-main-menu {
		width: 100%;
		padding-left: 0.4rem;
	}

	// -------------------------------------------
	.pd-left {
		padding: 0;
		border-bottom: 0;

		.pd-header {
			padding: 1.3rem 0.5rem;
			padding-bottom: 0;
		}

		//-conditional status bar-
		.top-container {
			border-top: 1px solid $neutral-400;
			border-bottom: 1px solid $neutral-400;
			margin-top: 1rem;

			.status-container {
				padding: 1.3rem 0.5rem;

				.status-subcontainer {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.status {
						margin: 0;
					}
					.year {
						margin: 0;
					}
				}
			}
		}
		.pd-about {
			padding: 1.3rem 0.5rem;
		}
		.categories {
			padding: 0;
			padding-left: 0.4rem;
		}
		.pd-team {
			padding-left: 0.4rem;
			padding-right: 0.4rem;
			.contributor {
				display: grid;
				grid-template-columns: 1fr;
			}
		}

		//-publication, media, partner, funding-
		.pd-publications,
		.pd-media,
		.pd-partners,
		.pd-funding {
			padding-left: 0.4rem;
			padding-right: 0.4rem;
		}

		.lastItem {
			padding-bottom: 2rem;
		}
	}
}
