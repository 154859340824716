//-search bar-
// strip off all default styling
.form,
fieldset,
select,
input {
	padding: 0;
	margin: 0;
	border: 0;
	appearance: none;
	color: inherit;
	background-color: inherit;
	&::placeholder {
		color: inherit;
	}
}
.bx {
	width: 100%;
	padding: 0.6rem 0.7rem;
	text-transform: uppercase;
	border-radius: 5px;
	border: 1px solid $neutral-400;
	background-color: $white;
	color: $neutral-500;
}

.form {
	display: flex;
	justify-content: space-between;
	@media (max-width: 835px) {
		flex-direction: column;
	}

	.child1 {
		display: flex;
        position: relative;
        @media (max-width: 400px) {
            flex-direction: column;
            gap: 10px;
        }
	}

	.child2 {
		@media (max-width: 835px) {
			margin-top: 10px;
		}
	}
	.expand-search {
		display: flex;
		align-items: center;
		.searchBx {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	@media (max-width: 400px) {
		margin: 0;
		display: block;
	}
}
//-adding icons-
.filterBx {
	width: 155px;
	margin-right: 16px;
    display: flex;
    gap: 10px;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
}

.sortBx {
	width: 155px;
	padding-left: 20px;
	background-image: url('../assets/downArrow.svg');
	background-repeat: no-repeat;
	background-size: 0.6rem;
	background-position: 92% 50%;
	cursor: pointer;
}

.searchBx {
	padding-left: 40px;
	background-image: url('../assets/magnifier.svg');
	background-repeat: no-repeat;
	background-size: 0.9rem;
	background-position: 16px;
	color: $soft-black;
	width: 174px;
	height: 100%;
	cursor: normal;

	&::placeholder {
		color: $neutral-500;
		opacity: 1;
	}
}
.commonCloseBtn {
	border: none;
	background-color: inherit;
	// position: absolute;
	// top: 25%;
	// right: 4%;
	// -------------

	cursor: pointer;

	.close-img {
		width: 0.8rem;
		background-color: inherit;
	}
}
.search-button {
	background-color: $soft-black;
	text-transform: uppercase;
	color: $neutral-100;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 100%;
	padding: 0.6rem 0.7rem;
}

// SUBMENU STARTS
.main-subform {
	min-width: 13.5%;
	background-color: $white;
	position: absolute;
	z-index: 10;
	width: 200px;
	top: 45px;
	@media (max-width: 400px) {
		width: 100%;
		min-width: 100%;
	}

	.subform-container {
		border: 1px solid $neutral-400;
		border-radius: 4px;
		margin-bottom: 10px;

		.subform-header {
			padding: 1rem 0.6rem;
			.top-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-transform: uppercase;

				.clear-btn {
					border: none;
					background-color: inherit;
					text-transform: uppercase;
					color: $neutral-500;
					cursor: pointer;
				}
			}
			.item-selected {
				margin-top: 0.3rem;
			}
		}

		.underline {
			width: 90%;
			margin: 0 auto;
		}

		.subform-main {
			.sel {
				display: block;
				width: 100%;
				cursor: pointer;
				padding: 1rem 0.6rem;

				.placeholder {
					color: $neutral-500;
				}

				background-image: url('../assets/downArrow.svg');
				background-repeat: no-repeat;
				background-size: 0.6rem;
				background-position: 95% 50%;

				.op {
					cursor: pointer;
				}
			}
			.view-result-btn {
				border: none;
				background-color: inherit;
				text-transform: uppercase;
				width: 90%;
				border-radius: 4px;
				background-color: $soft-black;
				color: $neutral-100;
				letter-spacing: 1px;
				margin: 1.5rem auto 1rem auto;
				padding: 0.4rem 0rem;
				cursor: pointer;
			}
		}
	}
}

// total result found section
.result-found-container {
	margin: 1rem 0rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
	.result-found {
		text-transform: capitalize;
	}
	.clear-filters-btn {
		border: none;
		background-color: inherit;
		text-transform: uppercase;
		color: $neutral-500;
		cursor: pointer;
		letter-spacing: 1px;
	}
}

// filtered tags
.filtered-tags-container {
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;
	margin-top: 1rem;

	.filt-btn {
		border: none;
		display: flex;
		align-items: center;
		padding: 0.3rem 0.4rem;
		border-radius: 4px;

		justify-content: space-between;
		cursor: pointer;

		.close-img {
			margin-left: 0.3rem;
			width: 0.6rem;
		}
	}
}
