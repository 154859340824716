.projects-list {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 2rem;
}

.project-title {
	color: $black;
	font-weight: bold;
	font-size: 0.9rem;
}

.project-subtitle {
	font-weight: 600;
	font-family: 'MDSystem';
	color: $soft-black;
	font-size: 0.8rem;
}

//-project card component styling-
.project-card {
	background-color: $neutral-200;
	border: 1px solid $neutral-400;
	border-radius: 10px;
	padding: 1rem 1rem 1rem;
	width: 100%;
	margin: 0 auto;

	.regular-14 {
		color: $black;
	}

	.project-card-intro {
		margin-top: 0.7rem;
	}
}
.project-link:last-child {
	margin-bottom: 3rem;
}
.project-card-metadata {
	display: flex;
	justify-content: space-between;
	font-size: 0.8rem;
	margin-top: 2rem;

	//-first child status-
	.status {
		display: flex;
		flex-direction: row;
		align-items: center;

		.current-status {
			display: flex;
			justify-content: space-between;
			min-height: 30px;
			padding: 0 12px;
			background-color: $black;
			border-radius: 4px;
			align-items: center;
		}

		.status-label {
			color: $neutral-200;
			text-transform: capitalize;
		}
		.status-icon {
			display: flex;
			align-items: center;
		}

		.flashing {
			margin: 0 0.7rem;
		}
		.singleDot {
			margin-left: 0.3rem;
		}

		//-styling date-
		.timeline {
			margin-left: 0.5rem;
			color: $neutral-500;
		}
	}

	//-second child categories/tags-
	.categories {
		display: flex;
		flex-wrap: wrap;
		padding-top: 0;
		li {
			color: $black;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			border: 1px solid $neutral-400;
			text-transform: capitalize;
		}
		li:last-child {
			border-radius: 0 4px 4px 0;
		}
		li:first-child {
			border-radius: 4px 0 0 4px;
		}
	}
}

// -animated three dot flashing icon-
.dot-flashing {
	position: relative;
	width: 5px;
	height: 5px;
	border-radius: 5px;
	background-color: $white;
	color: $white;
	animation: dot-flashing 1s infinite linear alternate;
	animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
}
.dot-flashing::before {
	left: -7px;
	width: 5px;
	height: 5px;
	border-radius: 5px;
	background-color: $white;
	color: $white;
	animation: dot-flashing 1s infinite alternate;
	animation-delay: 0s;
}
.dot-flashing::after {
	left: 7px;
	width: 5px;
	height: 5px;
	border-radius: 5px;
	background-color: $white;
	color: $white;
	animation: dot-flashing 1s infinite alternate;
	animation-delay: 1s;
}

@keyframes dot-flashing {
	0% {
		background-color: $white;
	}
	50%,
	100% {
		background-color: rgba(255, 255, 255, 0.2);
	}
}

// -single dot icon-
.singleDot {
	background: $white;
	border-radius: 50%;
	width: 12px;
	height: 12px;
}

// -hover effect on each project-
.project-card {
	border: none;
	transition: $main-transition;
	&:hover {
		background-color: $soft-black;
		color: $neutral-600;
		.project-card-intro,
        .project-title,
		.timeline,
		#hash,
		.regular-14,
		.category {
			color: $neutral-600;
		}
		.project-subtitle {
			color: $neutral-600;
		}
	}
}

// -media-
@media screen and (max-width: 466px) {
	.project-card-metadata {
		flex-direction: column;
		justify-content: center;
	}
	.status {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 0.9rem;

		.timeline {
			margin-top: 0.6rem;
		}
	}
	.categories {
		justify-content: center;
		justify-content: flex-start;
	}
}
@media screen and (max-width: 375px) {
	.status {
		align-items: flex-end;
		justify-content: flex-start;
		.timeline {
			margin-top: 0;
			padding: 0.1rem 0;
		}
	}
}
