// -logo-
.home-logo {
	height: 55px;
	width: 135px;
}

//  -main menu-
.header-container {
	width: 80%;
	margin: 0 auto;
	margin-top: 50px;
	margin-bottom: 25px;
}
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 2.8rem;
	background-color: $neutral-100;

	.main-menu {
		display: flex;

		.mm-list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.mm-list-item {
				color: $soft-black;
				margin-left: 1.5rem;
				&:first-child {
					margin-left: 0;
				}
				.item {
					text-transform: uppercase;
					&:hover {
						text-decoration: underline;
					}
				}

				.active {
					text-decoration: underline;
					text-underline-offset: 3px;
				}
				.inactive {
					color: $soft-black;
				}
			}
		}
	}
}

// -hamburger button-
.ham-btn {
	width: 2rem;
	height: 2rem;
	background-color: inherit;
	color: $soft-black;
	border: none;
	cursor: pointer;
	.hamburgerIcon {
		width: 65%;
		height: 65%;
	}
}

//  -side bar-
.sidebar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: $black;
	transition: $main-transition;
	transform: translate(-100%);
	.sidebar-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 90%;
    margin: 0 auto;
    margin-top: 50px;
		margin-bottom: 50px;
	}

	.sm-list {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-direction: column;
		margin-top: auto;
		background: $black;

		.sm-list-item {
			width: 100%;
			.sm-item {
				display: block;
				text-transform: uppercase;
				color: $white;
				width: 100%;
				text-align: center;
				border-top: 1px solid $neutral-500;
				padding: 1.3rem 0;
			}
			.sm-active {
				text-decoration: underline;
				text-underline-offset: 4px;
			}
		}

		.sidebar-footer {
			width: 100%;
			.footer-text {
				border-top: 1px solid $neutral-500;
				padding: 1.3rem 0;
				color: $neutral-400;
				text-align: center;
				font-size: 0.6rem;
				font-weight: 100;
			}
		}
	}
}
.close-btn {
	width: 1rem;
	height: 1rem;
	background-color: inherit;
	color: $white;
	border: none;
	.close-icon {
		width: 100%;
		height: 100%;

		&:hover {
			cursor: pointer;
		}
	}
}

.show-sidebar {
	transform: translate(0);
	z-index: 999;
	display: flex;
	flex-direction: column;
}
.ham-btn {
	display: none;
}

@media (max-width: 900px) {
	.header-container {
    width: 90%;
  }

	.home-logo {
		width: 97px;
		height: 40px;
	}
	.ham-btn {
		display: block;
	}
	.header .main-menu .mm-list {
		display: none;
	}
}

.border-with-corners {
  position: relative;
}

.border-with-corners::before,
.border-with-corners::after,
.border-with-corners-additional::before,
.border-with-corners-additional::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border: 2px solid black;
}

.border-with-corners::before {
  top: -5px;
  left: -5px;
  border-right: none;
  border-bottom: none;
}

.border-with-corners::after {
  bottom: -5px;
  right: -5px;
  border-left: none;
  border-top: none;
}

.border-with-corners-additional::before {
  top: -5px;
  right: -5px;
  border-left: none;
  border-bottom: none;
}

.border-with-corners-additional::after {
  bottom: -5px;
  left: -5px;
  border-right: none;
  border-top: none;
}

.border-with-corners.active {
	background-color: #2A2A2A;
	color: #FDFAF2 !important;
	text-decoration: none !important;
	padding: 8px;
	border-radius: 2px;
}

.border-with-corners.active::before,
.border-with-corners.active::after {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  border: none;
}

.active .border-with-corners-additional::before,
.active .border-with-corners-additional::after {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  border: none;
}