// -publications-
.proj-link {
	display: inline;
}
.project-list {
	list-style-type: none;
	.project {
		.proj-link {
			margin: 0px;
		}
	}
}

.publication-main {
	.pub-wrapper {
		padding-bottom: 8rem;

		.single-content {
			margin-top: 2rem;
		}
	}
}
//-publication card-
.single-publication {
	margin-top: 1rem;
	border-radius: 5px;

	border: 1px solid $neutral-400;

	.publication {
		padding: 1.5rem;

		.meta-container {
			.meta {
				display: flex;
				justify-content: space-between;

				.publication-type {
					padding: 0.4rem 0.45rem;
					border-radius: 5px;
					text-transform: uppercase;
					display: flex;
					align-items: center;
					order: 1;
				}

				.journal {
					color: $journal-col;
					background-color: $journal-bg;
					transition: $main-transition;
				}
				.popular {
					color: $popular-col;
					background-color: $popular-bg;
				}
				.perma-link {
					background-color: $neutral-300;
					border-radius: 4px;
					padding: 0.4rem 0.45rem;
					display: flex;
					justify-content: center;
					align-items: center;
					color: $black;
					font-size: 1rem;
					transition: $main-transition;

					&:hover {
						background-color: $neutral-500;
						color: $neutral-100;
					}
				}
			}
			//-heading-
			.journal-heading-container {
				padding: 0.7rem 0;

				.journal-heading {
					color: $soft-black;

					.link-container {
						display: inline-block;
						.heading-url {
							border: 0;
							background-color: $black;
							color: $white;
							border-radius: 5px;
							padding: 10px 15px;
							font-size: 14px;
							text-transform: uppercase;
							display: inline-flex;
							align-items: center;
							margin-top: 1rem;
							font-family: 'Sculpin Variable', 'Sculpin', -apple-system,
								BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
								Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
							transition: $main-transition;

							svg {
								margin-left: 0.15rem;
								font-size: 0.9rem;
								font-size: 1rem;
							}
							&:hover {
								background-color: $soft-black;
							}
						}
					}
				}
			}
		}
	}
	.authors-container {
		padding: 1.5rem;
		border-top: 1px solid $neutral-400;

		.authors {
			display: grid;
			align-items: flex-start;
			grid-template-columns: 120px 1fr;

			.auth-heading {
				color: $soft-black;
				text-transform: capitalize;
			}
			.authors-list {
				margin-left: 2.5rem;
				display: flex;
				flex-wrap: wrap;

				.authors-list-item {
					.author-link {
						max-width: 213px;
						color: $soft-black;
						text-decoration: underline;
						text-underline-offset: 3px;
					}
				}
			}
		}
	}
	.featured-projects-container {
		padding: 1.5rem;
		border-top: 1px solid $neutral-400;

		.featured-projects {
			display: grid;
			align-items: flex-start;
			grid-template-columns: 155px 1fr;

			.featured-projects-heading {
				color: $soft-black;
				text-transform: capitalize;
			}
			.project-list {
				color: $soft-black;
				margin-left: 1rem;

				.project {
					margin-bottom: 10px;
					.proj-link {
						text-decoration: underline;
						text-underline-offset: 2px;
					}
				}
			}
		}
	}
}

//-special S-
.specialS {
	text-transform: lowercase;
}

//-media query-
@media (max-width: 835px) {
	// single publication card
	.single-publication {
		.publication {
			.meta-container {
				.meta {
					flex-direction: row;
					a[class='perma-link'] {
						display: none;
					}
				}
			}
		}

		.authors-container {
			padding: 1.5rem;
			.authors {
				align-items: flex-start;
				grid-template-columns: 1fr;

				.authors-list {
					margin-left: 0;
					margin-top: 5px;
				}
			}
		}
	}

	// submenu
	.main-subform {
		min-width: 30.7% !important;
	}

	.featured-projects-container {
		padding: 0.8rem 0.3rem;
		.featured-projects {
			align-items: flex-start;
			grid-template-columns: 1fr !important;
			.project-list {
				margin-top: 5px;
				margin-left: 0px !important;
			}
		}
	}
}
@media (max-width: 400px) {
	//-single publication card-
	.single-publication {
		.publication {
			padding: 0.8rem 0.3rem;
		}
		.authors-container {
			padding: 0.8rem 0.3rem;
		}
	}

	//-author-
	.authors-container {
		padding: 1rem;

		.authors {
			grid-template-columns: 1fr !important;

			.auth-heading {
				margin: 0;
			}
			.authors-list {
				margin: 0;
				margin-left: 0px !important;
				padding-top: 1rem;
			}
		}
	}

	//-projects-
	.featured-projects-container {
		.featured-projects {
			grid-template-columns: 1fr;
			.project-list {
				margin-left: 0;
				.project {
					margin: 0;
					padding-top: 1rem;
				}
			}
		}
	}

	// submenu
	.main-subform {
		min-width: 89.6% !important;
	}
}
