* {
	margin: 0;
	padding: 0;
}
body,
ul,
li,
h1,
h2,
h3,
h4,
h5,
p,
a,
img,
blockquote,
input {
	margin: 0;
	padding: 0;
}
h1,
h2,
h3,
h4,
h5,
p {
	hyphens: none;
}

html {
	scroll-behavior: smooth;
}

ul {
	list-style: none;
}

button {
	display: block;
}

a {
	text-decoration: none;
	color: inherit;
	display: block;
}

img {
	max-width: 100%;
	display: block;
}
html {
	background-color: $neutral-100;
	overflow-x: hidden;
}

.wrapper {
	max-width: 1440px;
	width: 80%;
	margin: 0 auto;
	padding-top: 4rem;
}

@media (max-width: 835px) {
	.wrapper {
		width: 90%;
	}
}

.modal-content .paragraph {
	font-size: 16px;
	line-height: 24px;
}

.learn-more span {
	font-family: 'Sculpin Variable', 'Sculpin', -apple-system, BlinkMacSystemFont,
		'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
		sans-serif;
}
