.home-wrapper {
	width: 80%;
	margin: 0 auto;
	padding-bottom: 4.7rem;
	overflow-x: hidden;
}

.home-wrapper.first {
	display: flex;
	margin: 0;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	.welcome-container {
		padding-left: 10%;
	}
	.illustration {
		width: 100%;
		height: 350px;
		max-width: 550px;
	}
}

.carousel-con {
	margin-bottom: 4.7rem;
}

.paragraph a {
	text-decoration: underline;
	display: inline-block;
}

ol.contentful-ol,
ul.contentful-ul {
	list-style-type: decimal;
	margin-left: 20px;
	padding-left: 0;
	li {
		list-style-type: inherit;
	}
}

ul.contentful-ul {
	list-style-type: disc;
}

.welcome-container {
	width: 55%;
	padding: 4.7rem 0;
	padding-bottom: 2.7rem;

	h1 {
		font-size: 3rem;
		line-height: 3.5rem;
		hyphens: none;
	}

	.regular-16 {
		padding: 1.4rem 0;
		.paragraph {
			margin-bottom: 1rem;
			line-height: 1.2rem;
		}
	}
}

.about-container {
	width: 100%;
	padding-top: 0;

	.paragraph {
		margin-bottom: 1rem;
		line-height: 1.2rem;
	}
}
.recent-projects {
	display: flex;
	align-items: center;
	.bold-18:hover {
		cursor: pointer;
	}
	.down-arrow {
		display: flex;
		align-items: center;
		padding-left: 3px;
	}
}

.funding-container {
	padding: 4.7rem 0;
	padding-top: 0.7rem;
	h2 {
		line-height: 2.4rem;
	}

	.funding-logos {
		padding-top: 0.75rem;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 36px 42px;

		.funder-logo {
			display: flex;
			justify-content: center;
			align-items: center;
			a {
				display: block;
				cursor: pointer;
				img {
					height: 36px;
				}
			}
		}
	}
}
.home-contact {
	display: flex;
	align-items: stretch;
}

.home-address {
	width: 40%;
	background-color: $black;
	color: $white;
	padding: 1.9rem 2.65rem;
	border-radius: 0.5rem 0 0 0.5rem;
	.home-name {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.street-address {
		padding: 1.35rem 0;
	}

	.contact-methods {
		.bold-16 {
			word-wrap: break-word;
		}

		a {
			text-decoration: underline;
		}
	}
}

.stay-updated {
	width: 60%;
	background-color: $neutral-300;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1.9rem 2.65rem;
	border-radius: 0 0.5rem 0.5rem 0;
	h2 {
		font-size: 2.1rem;
		line-height: 2.5rem;
	}

	form {
		display: flex;
		gap: 0.5rem;
	}
	input {
		border: 1px solid $neutral-400;
		background-color: $white;
		width: 14rem;
		height: 2.2rem;
		border-radius: 0.25rem;
		padding-left: 0.7rem;
	}

	button {
		width: 6rem;
		font-family: 'sculpin';
		font-weight: 500;
		background-color: $black;
		color: $white;
		border: none;
		border-radius: 0.25rem;
	}
}

//  -media-
@media (max-width: 1300px) {
	.home-address,
	.stay-updated {
		width: 50%;
	}

	.home-address {
		.contact-methods {
			display: flex;
			flex-direction: column;
			gap: 0;
			padding: 0;
		}
	}
}

@media (max-width: 900px) {
	.home-wrapper {
		width: 90%;
	}

	.container-illustration {
		margin-left: 5%;
	}

	.home-wrapper.first {
		flex-direction: column-reverse;
		align-items: flex-end;
		.illustration {
			height: auto;
		}
		.welcome-container {
			padding-left: 0;
		}
	}

	.home-wrapper.first img {
		width: 100%;
		height: auto;
		position: initial;
	}

	.welcome-container {
		width: 90%;
		margin: 0 auto;
		h1 {
			font-size: 3rem;
		}
	}

	.funding-container > .funding-logos {
		grid-template-columns: repeat(2, 1fr);
	}

	.funding-container > .funding-logos > .funder-logo {
		height: 2.5rem;
	}
	.home-address,
	.stay-updated {
		width: 50%;
	}
	.home-name,
	.bold-18 {
		font-size: 0.75rem;
	}

	.street-address {
		font-size: 0.75rem;
	}
	.contact-methods {
		font-size: 0.65rem;
		padding-top: 2rem;
	}

	.stay-updated {
		justify-content: space-around;
		h2 {
			font-size: 1.2rem;
			line-height: 1.4rem;
		}
		form {
			justify-content: space-between;
		}
		input {
			width: 60%;
		}
		button {
			width: 30%;
			font-size: 0.7rem;
			padding: 0 0.8rem;
		}
	}

	.home-contact {
		flex-direction: column;
		height: auto;
		.home-address,
		.stay-updated {
			width: 100%;
		}

		.home-address {
			border-radius: 0.25rem 0.25rem 0 0;
			height: auto;
			.contact-methods {
				display: grid;
				grid-template-columns: auto auto;
				padding: 1.35rem 0;
			}
		}
		.stay-updated {
			border-radius: 0 0 0.25rem 0.25rem;
			h2 {
				line-height: 1.4rem;
			}
			button {
				width: 35%;
				font-size: 0.6rem;
			}
		}
	}
}

@media (max-width: 768px) {
	.welcome-container {
		padding: 1.8rem 0;
		h1 {
			font-size: 2.1rem;
			line-height: 2.5rem;
		}
	}

	.funding-container {
		padding: 4.4rem 0;
		padding-top: 0;
		h2 {
			font-size: 0.9rem;
		}
		.funding-logos {
			grid-template-columns: repeat(1, 1fr);
			.funder-logo {
				width: 100%;
				justify-content: center;
			}
		}
	}
}
@media (max-width: 480px) {
	.home-contact {
		.home-address {
			.contact-methods {
				display: flex;
				flex-direction: column;
				gap: 0;
			}
		}
		.stay-updated form {
			flex-direction: column;
			padding-top: 1rem;

			input,
			button {
				width: 100%;
			}

			button {
				height: 2.2rem;
			}
		}
	}
}
