.footer {
	width: 100%;
	background-color: $neutral-100;
	border-top: 1px solid $neutral-400;
}
.footer-wrapper {
	width: 80%;
	margin: 0 auto;
	padding: 2.3rem 0;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	gap: 1.1rem;
}

.logo-media,
.copyright-terms {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.6rem;
	font-weight: 500;
}

.logo-media {
	font-size: 0.7rem;
}

.media-container {
	display: flex;
	gap: 0.5rem;
}

.copyright-terms {
	color: $soft-black;
}
.copyright-terms a {
	text-decoration: underline;
	display: inline;
	text-underline-offset: 2.3px;
}

//  -media-
@media (max-width: 835px) {
	.footer-wrapper {
		width: 90%;
	}
}
@media (max-width: 480px) {
	.copyright-terms {
		flex-direction: column;
		gap: 0.35rem;
	}
	.logo-media {
		justify-content: flex-start;
	}
}
