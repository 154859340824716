.people-main .group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:last-child {
        padding-bottom: 4rem;
    }

    .category-title {
        width: 100%;
        text-transform: capitalize;
        color: $black;
    }
    .contributor {
        display: grid;
        grid-template-columns: repeat(2, minmax(278px, 1fr));
        gap: 1rem;
        margin-top: 1.5rem;

        .personCard {
            border: 1px solid $neutral-400;
            border-radius: 10px;

            .profile-link {
                display: flex;
                flex-direction: row;
                border-radius: 10px;
                padding: 0.1rem;

                // -first child of profile link-
                .profile-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    width: 20%;
                    min-width: 3.8rem;
                    img {
                        border-radius: 10px;
                        width: 100%;
                    }
                }

                // -second child of profile link-
                .profile-content {
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    margin-left: 0.4rem;

                    .profile-name {
                        width: 100%;
                        color: $black;
                        margin-bottom: 5px;
                    }
                    .title {
                        width: 100%;
                        color: $soft-black;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}

// -section description-
.section-description {
    text-transform: capitalize;
}

// -styling on hover-
.personCard {
    transition: $main-transition;
    .profile-link {
        border-radius: 10px;
    }
    &:hover {
        background-color: $soft-black;
        #name,
        #title {
            color: $neutral-100;
        }
    }
}

// -media-
@media (max-width: 835px) {
    .section-description {
        display: none;
    }
}
@media (max-width: 785px) {
    .group .contributor .profile-link .profile-content {
        width: 100%;

        .profile-name {
            font-size: 0.8rem;
        }

        .title {
            font-size: 0.6rem;
        }
    }
}

@media (max-width: 690px) {
    .people-main .group .contributor {
        grid-template-columns: 1fr;
    }
}
