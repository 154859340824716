.error-message {
    color: $red;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.5rem 0;
}

.contact {
    width: 100%;
    background-color: $neutral-100;
    overflow-x: hidden;

    .contact-wrapper {
        width: 60%;
        margin: 0 auto;
        padding: 6.25rem 0;
    }

    .form-container {
        min-height: 21rem;
    }
    form fieldset {
        border: none;
        display: flex;
        flex-direction: column;

        input,
        select {
            padding: 0.7rem;
            border: 1px solid $neutral-400;
            background-color: $white;
            border-radius: 0.25rem;
        }
        label {
            padding: 0.7rem 0;
        }
        label::after {
            content: '*';
            color: $neutral-500;
            margin-left: 0.25rem;
        }
        select {
            padding-right: 1rem;
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="$black" d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/></svg>');
            background-repeat: no-repeat;
            background-position: right 0.8rem center;
            background-size: 0.5rem 0.5rem;
            cursor: pointer;
            option {
                color: $black;
            }
        }
    }

    .name-email-subject {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        fieldset {
            width: 33%;
  
        }
    }
    .contact-message {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 1.75rem;
        label {
            padding: 0.7rem 0;
        }
        label::after {
            content: '*';
            color: $neutral-500;
            margin-left: 0.25rem;
        }
        textarea {
            resize: none;
            padding: 0.7rem;
            border: 1px solid $neutral-400;
            border-radius: 0.25rem;
            background-color: $white;
            &::placeholder {
                color: $black;
            }
        }
    }
    hr {
        display: block;
        width: 100%;
        color: none;

        border: 1px solid $neutral-400;

        border-bottom: none;
    }

    .button-container {
        padding-top: 2rem;
        display: flex;
        gap: 0.5rem;

        .text-box {
            width: 80%;
            background-color: $neutral-300;
            padding: 1rem 1rem;
            border-radius: 0.25rem;

            &.form-submitted {
                background-color: $neutral-400;
            }
        }
        button {
            background-color: $black;
            color: $neutral-100;
            width: 20%;
            border: none;
            border-radius: 0.25rem;
        }
        button:hover {
            background-color: $soft-black;
            color: $neutral-200;
        }
    }
    .address-container {
        display: flex;
        justify-content: center;
        .address {
            width: 50%;
            padding: 2rem;
            background-color: $black;
            color: $white;
            border-radius: 0.5rem 0 0 0.5rem;
            .bold-18 {
                font-style: normal;
            }
        }
        .street-address {
            padding: 1.4rem 0;
        }
        .contact-methods {
            padding-top: 2rem;
            .bold-16 {
                word-wrap: break-word;
                font-style: normal;
            }
        }

        .map {
            width: 50%;
        }
    }
}
.map-container {
    height: 348px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
// -media-
@media (max-width: 1300px) {
    .contact {
        .address-container {
            .contact-methods {
                display: flex;
                flex-direction: column;
                gap: 0;
                padding: 0;
            }
        }
    }
}
@media (max-width: 1040px) {
    .contact {
        .contact-wrapper {
            width: 90%;
            margin: 0 auto;
            padding: 4.5rem 0;
        }
    }
}
@media (max-width: 768px) {
    .contact {
        .contact-wrapper {
            padding: 3.4rem 0;
        }
        .address-container {
            flex-direction: column;
            .address,
            .map {
                width: 100%;
                min-height: 15rem;
                border-radius: 0.5rem;
                border-radius: 0 0 0.5rem 0.5rem;
            }
            .address {
                border-radius: 0.5rem 0.5rem 0 0;
            }
            .contact-methods {
                display: grid;
                grid-template-columns: auto auto;
            }
        }
        .street-address {
            .bold-18 {
                font-size: 0.75rem;
            }
        }
        .contact-methods {
            .bold-16 {
                font-size: 0.65rem;
            }
        }
    }
}

@media (max-width: 480px) {
    .contact {
        form fieldset {
            flex-direction: column;
        }
        .name-email-subject {
            flex-direction: column;
            fieldset {
                width: 100%;
            }
        }
        .button-container {
            flex-direction: column;

            .text-box,
            button {
                width: 100%;
                min-height: 2.2rem;
            }
        }
        .address-container {
            flex-direction: column;
            align-items: center;
            .address,
            .map {
                width: 100%;
                border-radius: 0.5rem;
            }
            .address {
                border-radius: 0.5rem 0.5rem 0 0;
            }
        }
    }
}
@media (max-width: 375px) {
    .contact {
        .address-container {
            .contact-methods {
                display: flex;
                flex-direction: column;
            }
        }
    }
    .map-container {
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
    }
}
