$black: #1a1a1a;
$soft-black: #2a2a2a;
$yellow: #fbe960;
$red: #e74c3c;
$white: #ffffff;
$neutral-600: #F7F5F2;
$neutral-500: #7a7669;
$neutral-500-opacity-15: rgba(122, 118, 105, 0.15);
$neutral-400: #dad7c9;
$neutral-300: #efece3;
$neutral-200: #f3f1e8;
$neutral-100: #fdfaf2;
$journal-col: #3d4e97;
$journal-bg: #ecece9;
$popular-col: #224e57;
$popular-bg: #e8efeb;

// misc
$main-transition: 0.2s all linear;
