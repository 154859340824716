@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'MDSystem';
    src: url('./fonts/Non-Commercial/MDSystem/MDSystem-Bold.otf')
        format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'MDSystem';
    src: url('./fonts/Non-Commercial/MDSystem/MDSystem-Medium.otf')
        format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'MDSystem';
    src: url('./fonts/Non-Commercial/MDSystem/MDSystem-Regular.otf')
        format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MDSystem';
    src: url('./fonts/Non-Commercial/MDSystem/MDSystem-Semibold.otf')
        format('opentype');
    font-weight: 600;
    font-style: normal;
}

body {
    margin: 0;
    font-family: 'Sculpin Variable', 'Sculpin', -apple-system,
        BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: 'MDSystem', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
