.training {
  .training-border:not(:last-child) {
    border-bottom: 1px solid $neutral-400;
  }
  .training-wrapper {
    color: $soft-black;
    padding-bottom: 3rem;
  }

  .training-container {
    .training-title {
      color: $black;
    }

    .training-link {
      padding: 1rem 0;
    }
    .training-description {
      padding: 1rem 0;
      .paragraph:not(:last-child) {
        margin-bottom: 1rem;
    }
    }

    .training-link {
      background-color: $neutral-200;
      padding: 1rem 2rem;
      border-radius: 0.25rem;
      .training-anchor {
        display: inline;
        text-decoration: underline;
      }
    }
  }
}
