html {
    // 1rem = 20px
    font-size: 125%;
    font-family: sculpin, sans-serif;
}

h1 {
    // 42px = 2.1rem
    font-family: sculpin, sans-serif;
    font-size: 2.1rem;
    font-weight: 700;
    line-height: 2.5rem;
}
h2 {
    // 24px = 1.2rem;
    font-family: sculpin, sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4rem;
}
h3 {
    // 18px = 1.2rem;
    font-family: sculpin, sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.1rem;
}

.bold-18 {
    // 18px = 0.9rem
    font-family: MDSystem;
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.1rem;
}
.semi-18 {
    // 18px = 0.9rem
    font-family: MDSystem;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.1rem;
}

.semi-16 {
    font-family: MDSystem;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1rem;
}

.semi-20 {
    font-family: MDSystem;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.1rem;
}
// --------
.bold-16 {
    // 16px = 0.8rem
    font-family: MDSystem;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1rem;
}
.medium-16 {
    // 16px = 0.8rem
    font-family: MDSystem;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1rem;
}
.regular-16 {
    // 16px = 0.8rem
    font-family: MDSystem;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.2rem;
}
// ------------------------
.semi-14 {
    // 14px = 0.7rem
    font-family: MDSystem;
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 0.9rem;
}
.medium-14 {
    // 14px = 0.7rem
    font-family: MDSystem;
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 0.9rem;
}
.regular-14 {
    // 14px = 0.7rem
    font-family: MDSystem;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 0.9rem;
}

// -----------------------------
.regular-caps {
    // 13px = 0.65rem
    font-family: sculpin, sans-serif;
    font-size: 0.65rem;
    font-weight: 500;
    line-height: 0.8rem;
}
.bold-caps {
    // 13px = 0.65rem
    font-family: sculpin, sans-serif;
    font-size: 0.65rem;
    font-weight: 700;
    line-height: 0.8rem;
}
