.heading-container {
	display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  h2 {
    font-size: 2.1rem;
    font-weight: 700;
  }
}

.custom-arrow {
	a {
		display: flex;
		gap: 10px;
    font-family: 'Sculpin Variable', 'Sculpin', -apple-system,
        BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
	}
  &:hover {
    text-decoration: underline;
  }
}
.project-container {
  max-width: 95%;
  height: 14.5rem;
  background-color: $neutral-300;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;


  .tag-box {
    color: $soft-black;
  }

  &:hover {
    background-color: $black;
    color: $white;
    .project-title {
      color: $white;
    }
    .project-subtitle {
			color: $white
		}
    .tag-container > .tag-box {
      border: 1px solid $white;
      color: $white;
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
  }

  .subtitle {
    display: flex;
    align-items: center;
  }
  .subtitle > h3 {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .project-description {
    padding-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .tag-container {
    padding-top: 1.2rem;
    display: flex;

    .tag-box {
      padding: 0.5rem;
      border: 1px solid $neutral-400;
      border-radius: 0.1rem;
      object-fit: contain;
    }
  }
}

.slick-dots li button::before {
  font-size: 0.45rem;
}

//! Media Queries

@media (max-width: 1500px) {
  .tag-container > .tag-box {
    font-size: 0.5rem;
    padding: 0.2rem;
  }
}

@media (max-width: 768px) {
  .heading-container > h2 {
    font-size: 1.6rem;
  }

  .custom-arrow {
    font-size: 0.9rem;
  }

  .project-container {
    max-width: 98%;
    height: 16.5rem;

    .project-title {
      font-size: 0.6rem;
    }
    .subtitle {
      font-size: 0.7rem;
    }
  }
}