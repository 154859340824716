.error-main {
    height: 75vh;

    .error-container {
        text-align: center;
        max-width: 40rem;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100%;

        .error-heading {
            font-size: 7.5rem;
            opacity: 0.7;
        }
        .error-subheading {
            text-transform: capitalize;
            font-size: 1.5rem;
            opacity: 0.7;
            margin-top: 3rem;
        }
        .error-description {
            margin-top: 1.5rem;
        }
    }
}

@media (max-width: 375px) {
    .error-main {
        height: 70vh;
    }

    .error-description {
        padding: 0.3rem 0.5rem;
    }
}
